import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Space, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { dateFormat } from '../../../assets/common_funcs/date_handle';

import ProductsList from '../../base_components/products_list';
import ListsItem from '../../base_components/list_item';
import { NoticesRequestGet } from './notices_requests';
import CreateNotices from './create_notices';
import UpdateNotices from './update_notices';

// 列表编辑 页面

const NoticesList = () => {
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const reqParams = useRef({page: 0});

  const [noticesData, setNoticesData] = useState([]);
  const [noticesRequestGetCount, setNoticesRequestGetCount] = useState(0);

  const [createNoticesVisible, setCreateNoticesVisible] = useState(false);
  const [updateNoticesVisible, setUpdateNoticesVisible] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});

  useEffect(() => {
    // 加载、更新或创建数据后 重新读取列表
    reloadLists();
  }, [createNoticesVisible, updateNoticesVisible]);

  const loadMore = () => {
    // 读取更多列表
    if (hasMore && !loading) {
      reqParams.current = { ...reqParams.current, page: reqParams.current.page + 1 };
      setNoticesRequestGetCount(noticesRequestGetCount + 1);
      setLoading(true);
    }
  };

  const reloadLists = () => {
    // 重新读取数据
    setNoticesData([]);
    setHasMore(true);
    setLoading(false);
    reqParams.current = {page: 0};
    setTimeout(() => {
      loadMore();
    }, 500);
  }

  const judgeHasMore = resp => {
    if (Object.keys(resp).includes('next')
      && typeof resp.next === 'string'
      && resp.next.length > 5) {
      return true;
    }
    return false;
  }

  const noticesGetResponse = resp => {
    setLoading(false);
    if (Object.keys(resp).includes('code')
      && resp.code === 1000
      && Object.keys(resp).includes('results')) {
      setNoticesData(noticesData.concat(resp.results));
      setHasMore(judgeHasMore(resp));   // 判断是否还有更多
    } else {
      console.log('Error: get data...');
    }
  }

  const itemDetailClick = (index) => {
    // 打开详情编辑窗口
    // console.log(noticesData[index]);
    let selObj = { ...noticesData[index] };
    // 日期转 moment 时间
    selObj.end_time = moment(dateFormat(selObj.end_time), 'YYYY-MM-DD HH:mm:ss');
    setSelectedObj(selObj);
    setUpdateNoticesVisible(true);
  }

  const ItemRendererComponent = ({ item, index }) => {
    // 必须 每一行渲染 的组件, 修改 ~~~~~~~ item keys ~~~~~~;
    // console.log(item);
    return (<div>
      <ListsItem
        listsIndex={index}
        productTitle={item.end_time}
        productEffects={item.content}
        sourcePrice={0}
        salePrice={0}
        clickDetailHandle={itemDetailClick}
      ></ListsItem>
    </div>);
  }

  return (
    <div>
      <Row>
        <Col span={14}>
          <Space>
            <h4>Notices</h4>
            <Tooltip title="刷新">
              <Button onClick={reloadLists} shape="circle" icon={<ReloadOutlined />}></Button>
            </Tooltip>
          </Space>
        </Col>
        <Col span={10}>
          <div style={{ width: '100%', height: 'auto' }}>
            <Button style={{ float: 'right' }} type="link" onClick={() => setCreateNoticesVisible(true)}>新建</Button>
          </div>
        </Col>
      </Row>
      <NoticesRequestGet requestCount={noticesRequestGetCount}
        reqParams={reqParams.current}
        responseDataHandle={noticesGetResponse}></NoticesRequestGet>
      <ProductsList
        productsData={noticesData}
        loadMore={loadMore}
        hasMore={hasMore}
        ItemRendererComponent={ItemRendererComponent}></ProductsList>

      <CreateNotices visible={createNoticesVisible} setVisible={setCreateNoticesVisible}></CreateNotices>
      <UpdateNotices
        visible={updateNoticesVisible}
        setVisible={setUpdateNoticesVisible}
        oldObj={selectedObj}></UpdateNotices>
    </div>
  )
}

export default NoticesList;
