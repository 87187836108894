import React, { useRef, useState, useEffect } from 'react';
import { Modal, Divider, message } from 'antd';

// import StsUploadFile from '../../upload_files/sts_upload_file';
import { NoticesRequestPost } from './notices_requests';
import { CustomForm } from '../../base_components/form_component';

import {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  formInitialObj
} from './notices_columns_label_config';

// 新建 Modal

const CreateNotices = ({ visible, setVisible }) => {
  const [cleanNoticesImageCount, setCleanNoticesImageCount] = useState(0);
  // const noticesImageObj = useRef({});

  const [initFormCount, setInitFormCount] = useState(0);

  const [postRequestCount, setPostRequestCount] = useState(0);
  const reqObject = useRef({});
  const postResponseDataHandle = (respObj) => {
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000) {
      setVisible(false);
    } else {
      message.error('网络错误...');
    }
  }

  useEffect(() => {
    setCleanNoticesImageCount(cleanNoticesImageCount + 1);  // 清空图片
    setInitFormCount(initFormCount + 1);  // 初始化表格
  }, [visible]);

  // const noticesImageUploaded = (respObj, fileKey) => {
  //   // 图片上传后操作
  //   noticesImageObj.current = { [fileKey]: respObj.image_cos_name };
  // }

  const onFinish = (values) => {
    // 提交表单
    let upObj = { ...values };
    let newDate = new Date(upObj.end_time);
    upObj.end_time = parseInt(newDate.getTime());    // 时间转时间戳
    reqObject.current = upObj;
    setPostRequestCount(postRequestCount + 1);
  }

  return (
    <Modal visible={visible}
      title="Notices 新建"
      closable={false}
      footer={null}
    >
      {/* <div style={{ width: '100%', height: 'auto', textAlign: 'center' }}>
        <StsUploadFile
          uploadedResponseHandle={noticesImageUploaded}
          fileKey="image"
          cleanFileListCount={cleanNoticesImageCount}></StsUploadFile>
      </div> */}
      <Divider></Divider>

      <CustomForm
        columns={formKeys}
        disabledKeys={formDisabledKeys}
        notShowKeys={formNotShowKeys}
        formKeyLabelObj={formKeysLabelsObj}
        formKeySelectComponentsObj={formKeySelectComponentsObj}
        onFinish={onFinish}
        cancelHandle={() => setVisible(false)}
        showDeleteButton={false}
        formInitialObj={formInitialObj}
        initFormCount={initFormCount}
      ></CustomForm>

      <NoticesRequestPost
        requestCount={postRequestCount}
        reqObject={reqObject.current}
        responseDataHandle={postResponseDataHandle}></NoticesRequestPost>
    </Modal>
  )
}

CreateNotices.defaultProps = {
  visible: false,
  setVisible: (val) => console.log(val),
}

export default CreateNotices
