import React, { useRef, useState, useEffect } from 'react';
import { Modal, Divider, message } from 'antd';

import StsUploadFile from '../../upload_files/sts_upload_file';
import { NoticesRequestPut, NoticesRequestDelete  } from './notices_requests';
import { CustomForm } from '../../base_components/form_component';

import {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  formInitialObj
} from './notices_columns_label_config';

// 编辑 Notices

const UpdateNotices = ({ visible, setVisible, oldObj }) => {
  // const [cleanNoticesImageCount, setCleanNoticesImageCount] = useState(0);
  // const noticesImageObj = useRef({});

  // const [oldNoticesImageUrl, setOldNoticesImageUrl] = useState('');
  const [oldFormObj, setOldFormObj] = useState({});

  const [initFormCount, setInitFormCount] = useState(0);

  const [putRequestCount, setPutRequestCount] = useState(0);
  const reqObject = useRef({});
  const putResponseDataHandle = (respObj) => {
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000) {
      message.success('修改成功');
      setVisible(false);
    } else {
      message.error('网络错误...');
    }
  }

  const [deleteRequestCount, setDeleteRequestCount] = useState(0);
  const deleteNotices = () => {
    // 删除当前对象
    setDeleteRequestCount(deleteRequestCount + 1);
  };

  useEffect(() => {
    setInitFormCount(initFormCount + 1);  // 初始化表格
    // setOldNoticesImageUrl(oldObj.image);   // 初始化旧图片
    setOldFormObj({ ...oldObj });  // 初始化旧formObj
  }, [oldObj]);

  // const noticesImageUploaded = (respObj, fileKey) => {
  //   // 轮播图上传后操作
  //   noticesImageObj.current = { [fileKey]: respObj.image_cos_name };
  // }

  const onFinish = (values) => {
    // 提交表单
    let upObj = { ...values };
    // 时间转时间戳
    let newDate = new Date(upObj.end_time);
    upObj.end_time = parseInt(newDate.getTime());    // 时间转时间戳
    // console.log(upObj.end_time);
    reqObject.current = upObj;
    setPutRequestCount(putRequestCount + 1);
  }

  return (
    <Modal visible={visible}
      title="Notices"
      closable={false}
      footer={null}
    >
      {/* <div style={{width: '100%', height: 'auto', textAlign: 'center'}}>
        <StsUploadFile
          oldImageUrl={oldNoticesImageUrl}
          uploadedResponseHandle={noticesImageUploaded}
          cleanFileListCount={cleanNoticesImageCount}
          fileKey="image"
        ></StsUploadFile>
      </div> */}
      <Divider></Divider>

      <CustomForm
        columns={formKeys}
        disabledKeys={formDisabledKeys}
        notShowKeys={formNotShowKeys}
        formKeyLabelObj={formKeysLabelsObj}
        formKeySelectComponentsObj={formKeySelectComponentsObj}
        onFinish={onFinish}
        cancelHandle={() => setVisible(false)}
        showDeleteButton={true}
        deleteHandle={deleteNotices}
        updateFormObj={oldFormObj}
        formInitialObj={{}}
        initFormCount={initFormCount}
      ></CustomForm>

      <NoticesRequestPut
        requestCount={putRequestCount}
        reqObject={reqObject.current}
        responseDataHandle={putResponseDataHandle}
        id={oldFormObj.id}></NoticesRequestPut>
      <NoticesRequestDelete
        requestCount={deleteRequestCount}
        responseDataHandle={(respObj) => setVisible(false)}
        id={oldFormObj.id}></NoticesRequestDelete>
    </Modal>
  )
}

UpdateNotices.defaultProps = {
  visible: false,
  setVisible: (val) => console.log(val),
  oldObj: {}
}

export default UpdateNotices;
