import React from 'react';

import AxiosRequest from '../../apis';

// Notices 的request请求

// 需要修改apiLists
const apiLists = ['notice-admin/v1/notices'];

const NoticesRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // Notices Get请求
  const api = apiLists[0];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
NoticesRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const NoticesRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // Notices post请求
  const api = [].concat(apiLists)[0];
  const method = 'post';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
NoticesRequestPost.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
}

const NoticesRequestPut = ({ requestCount, reqObject, responseDataHandle, id }) => {
  // Notices put请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'put';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
NoticesRequestPut.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

const NoticesRequestDelete = ({ requestCount, responseDataHandle, id }) => {
  // Notices delete请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'delete';
  const reqParams = {};
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
NoticesRequestDelete.defaultProps = {
  requestCount: 0,
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

export {
  NoticesRequestGet,
  NoticesRequestPost,
  NoticesRequestPut,
  NoticesRequestDelete
};
