import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import NoticesList from '../components/wx_store/notices/notices_list';

const NoticesManagePage = () => (
  <SiteLayout>
    <SEO title="WX Notices" />
    <NoticesList></NoticesList>
  </SiteLayout>
)

export default NoticesManagePage;
