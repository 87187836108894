import React from 'react';
import { DatePicker } from 'antd';
import { CustomSelect } from '../../base_components/select_component';

// Notices 列 配置

// ~~~~~~ 表单列, 需添加 ~~~~~~
const formKeys = [
  "id", "handle_staff_id", "content", "end_time", "add_time",
  "update_time", "explains", "is_delete"
];

// ~~~~~~ 表单不能编辑列, 根据实际添加 ~~~~~~
const formDisabledKeys = ["id", "handle_staff_id", "add_time", "update_time"];

// ~~~~~~ 表单不显示列, 根据实际添加 ~~~~~~
const formNotShowKeys = ["handle_staff_id",];

// ~~~~~~ 表单列名称 label, 需添加 ~~~~~~
const formKeysLabelsObj = {
  "id": "ID", "handle_staff_id": "编辑员ID", "content": "内容", "end_time": "结束时间",
  "add_time": "创建时间", "update_time": "更新时间", "explains": "说明", "is_delete": "已删除"
};

//  ~~~~~~ 选择框, 根据实际添加 ~~~~~~
const SelectIsDelete = (<CustomSelect options={[{ value: 0, label: '否' }, { value: 1, label: '是' }]} />);
const SelectEndTime = (<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />);
const formKeySelectComponentsObj = {
  "is_delete": SelectIsDelete,
  "end_time": SelectEndTime
};

//  ~~~~~~ 表单是图片列, 根据实际添加 ~~~~~~
const formKeysIsImages = [];

//  ~~~~~~ 表单get 请求条件key, 根据实际添加 ~~~~~~
const formGetRequestParamsKeys = [];

//  ~~~~~~ 表单初始化值, 根据实际添加 ~~~~~~
const formInitialObj = {
  "id": "", "handle_staff_id": "0", "content": "", "end_time": "",
  "add_time": "", "update_time": "", "explains": "", "is_delete": 0
}

export {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  formInitialObj
};
